import { render, staticRenderFns } from "./ArchivesComp.vue?vue&type=template&id=34f49862&scoped=true&"
import script from "./ArchivesComp.vue?vue&type=script&lang=js&"
export * from "./ArchivesComp.vue?vue&type=script&lang=js&"
import style0 from "./ArchivesComp.vue?vue&type=style&index=0&id=34f49862&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f49862",
  null
  
)

export default component.exports