import { render, staticRenderFns } from "./CallForPaperComponent.vue?vue&type=template&id=596b6592&scoped=true&"
import script from "./CallForPaperComponent.vue?vue&type=script&lang=js&"
export * from "./CallForPaperComponent.vue?vue&type=script&lang=js&"
import style0 from "./CallForPaperComponent.vue?vue&type=style&index=0&id=596b6592&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596b6592",
  null
  
)

export default component.exports